import styled from "styled-components";

// Store
import { useSelector } from "react-redux";
import userSelectors from "../../store/user/selectors";

// Services
import intl from "../../services/intl";

// Utils
import { getBundleCartLimit } from "../../utils/bundle";
import { getProductSkuForPlanId } from "../../utils/planToProduct";
import { Opacity, rem, responsive } from "../../utils/style";

// Components
import Container from "../Container";
import MagicLink from "../MagicLink";
import Row from "../Row";
import Text from "../Text";
import BarrierBusters from "./BarrierBusters";
import CartItem from "./CartItem";
import CartTopBannerArea from "./CartTopBannerArea";
import ProductPromotionCartItem from "./ProductPromotionCartItem";
import CartReceipt from "./Receipt";
import Upsell from "./Upsell";
import BundleOffer from "./BundleOffer";

// Styled Elements
const ProductContainer = styled(Container)`
  padding-top: 48px;

  ${responsive.sm`
    padding-top: 76px;
  `}
`;

const ItemWrapper = styled.div.attrs({
  className: "col-12 col-sm-7",
})`
  display: flex;
  flex-direction: column;
  padding: 0;

  ${responsive.sm`
    padding-right: 10px;
    margin-bottom: 32px;
  `};

  ${responsive.md`
    padding-right: 20px;
    margin-bottom: 56px;
  `};
`;

const ReceiptWrapper = styled.div.attrs({
  className: "col-12 col-sm-5 col-md-4 offset-md-1",
})`
  padding-left: 20px;
  padding-right: 20px;

  ${responsive.sm`
    padding-left: 10px;
    padding-right: 0;
  `};

  ${responsive.md`
    padding-left: 20px;
  `};
`;

const Headline = styled.div.attrs({
  role: "section",
  "aria-labelledby": "review-order-heading",
})`
  margin: 24px 0 16px;

  ${responsive.sm`
    margin: 24px 0 16px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
  `};

  ${responsive.md`
    margin-top: 40px;
    margin-bottom: 24px;
  `};

  h1 {
    font-weight: 500;
    font-size: ${rem(26)};
    line-height: ${rem(36)};
    letter-spacing: -0.3px;
    margin: 0;

    ${responsive.md`
      font-size: ${rem(34)};
      line-height: ${rem(40)};
      letter-spacing: -0.5px;
    `};
  }

  p {
    font-weight: 500;
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    margin: 8px 0 0;
    color: rgba(20, 43, 111, ${Opacity.light});

    a {
      color: inherit;
      border-bottom: 1px solid currentColor;
    }

    ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(22)};
      margin: 0;
	  `};
  }
`;

const ProductsWrapper = styled.div`
  margin-left: 20px;
  margin-right: 20px;

  ${responsive.sm`
    margin-left: 0;
    margin-right: 0;
  `};
`;

const PaddingWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  ${responsive.sm`
    padding-left: 0;
    padding-right: 0;
  `};
`;

const HeadlineWrapper = styled(PaddingWrapper).attrs({
  className: "col-12 col-sm-7",
})`
  ${responsive.sm`
    padding-right: 10px;
  `};

  ${responsive.md`
    padding-right: 20px;
  `};
`;

export default function ReviewOrderComponent(props) {
  const isLoggedIn = useSelector(userSelectors.isLoggedIn);

  const {
    cart,
    cartProducts,
    cartQuantity,
    isOneTimeOnly,
    contentfulProducts,
    checkoutUrl,
    isProcessing,
    hasActiveSubscription,
  } = props;
  const quantityLimit = getBundleCartLimit();

  const isAddingToSubscription =
    hasActiveSubscription && cartQuantity !== quantityLimit;

  const upsellCartAmount = 2;
  const shouldShowUpsell =
    !isAddingToSubscription && cartQuantity <= upsellCartAmount;

  return (
    <>
      <ProductContainer>
        <Row>
          <div className="col-12 p-sm-0">
            <CartTopBannerArea />
          </div>
          <HeadlineWrapper>
            <Headline>
              <h1 id="review-order-heading">
                {isAddingToSubscription
                  ? intl.t(
                      "cart.review-order.heading-is-adding",
                      "Adding to your subscription",
                    )
                  : intl.t("cart.review-order.heading", "Review order")}
              </h1>
              {!isLoggedIn && (
                <p>
                  <Text
                    id="cart.review-order.not-logged"
                    defaultMessage="Already have an account?"
                  />{" "}
                  <AuthenticationLink />
                </p>
              )}
            </Headline>
          </HeadlineWrapper>
        </Row>

        <Row>
          <ItemWrapper>
            <ProductsWrapper>
              <div>
                {cartProducts.map((cartProduct, i) => {
                  return (
                    <CartProduct
                      key={i}
                      cartProduct={cartProduct}
                      contentfulProducts={contentfulProducts}
                      isProcessing={isProcessing}
                      cartQuantity={cartQuantity}
                    />
                  );
                })}

                <ProductPromotionCartItem />
              </div>

              <BundleOffer
                isAddingToSubscription={isAddingToSubscription}
                cartQuantity={cartQuantity}
              />
            </ProductsWrapper>
            {shouldShowUpsell && (
              <PaddingWrapper>
                <Upsell
                  contentfulProducts={contentfulProducts}
                  cartQuantity={cartQuantity}
                  cartProducts={cartProducts}
                  isProcessing={isProcessing}
                  upsellSkus={cart.productRecommendations}
                />
              </PaddingWrapper>
            )}
            <PaddingWrapper className="d-block d-sm-none">
              <BarrierBusters oneTime={isOneTimeOnly} />
            </PaddingWrapper>
          </ItemWrapper>
          <ReceiptWrapper>
            <CartReceipt
              cart={cart}
              cartProducts={cartProducts}
              contentfulProducts={contentfulProducts}
              checkoutUrl={checkoutUrl}
              isProcessing={isProcessing}
              isLoggedIn={isLoggedIn}
              isAddingToSubscription={isAddingToSubscription}
            />
            <div className="d-none d-sm-block">
              <BarrierBusters oneTime={isOneTimeOnly} />
            </div>
          </ReceiptWrapper>
        </Row>
      </ProductContainer>
    </>
  );
}

function CartProduct({
  cartProduct,
  contentfulProducts,
  isProcessing,
  cartQuantity,
}) {
  const productSku = getProductSkuForPlanId(cartProduct.planId);
  const contentfulProduct = contentfulProducts.find((product) => {
    return productSku === product.sku;
  });

  return (
    <CartItem
      key={cartProduct.key}
      cartProduct={cartProduct}
      contentfulProduct={contentfulProduct}
      isProcessing={isProcessing}
      cartQuantity={cartQuantity}
      simplified={true}
    />
  );
}

function AuthenticationLink() {
  const text = intl.t("general.link-sign-in", "Sign in");

  return (
    <MagicLink to="/login" state={{ redirect: "/cart" }} title={text}>
      {text}
    </MagicLink>
  );
}
