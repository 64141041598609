import { Component } from "react";
import { graphql } from "gatsby";

// Components
import LoadingWrapper from "../../components/global/LoadingWrapper";
import PageSEO from "../../components/seo/Page";
import Cart from "../../components/cart/Cart";

// Utils
import {
  getBundleCartLimit,
  getPlanIdAndQuantityArray,
} from "../../utils/bundle";

// Redux
import { connect } from "react-redux";
import { clearCartAndAddProducts } from "../../store/cart/actions";
import appSelectors from "../../store/app/selectors";
import cartSelectors from "../../store/cart/selectors";
import userSelectors from "../../store/user/selectors";

class CartPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seo: {
        pagePath: "cart",
        title: "Your Cart",
      },
      hasProcesssedQueryParams: false,
      productsToAdd: [],
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const pid = params.get("pid");
    const productOfferId = params.get("poid");

    if (!pid && !productOfferId) {
      this.setState({
        hasProcesssedQueryParams: true,
      });
      return;
    }

    if (productOfferId) {
      this.setState({
        productsToAdd: [
          {
            quantity: 1,
            productOfferId: productOfferId,
          },
        ],
      });
      return;
    }

    const planIds = [];
    const productLimit = getBundleCartLimit();

    // Check for valid planIds.
    pid.split(",").forEach((id) => {
      if (planIds.length >= productLimit) return;
      if (this.props.validPlanIds.includes(id)) {
        planIds.push(id);
      }
    });

    const productsToAdd = getPlanIdAndQuantityArray(planIds);

    // If there are no valid planIds, consider the query params processed.
    if (!productsToAdd.length) {
      this.setState({
        hasProcesssedQueryParams: true,
      });
    }

    this.setState({
      productsToAdd,
    });
  }

  componentDidUpdate() {
    const { productsToAdd, hasProcesssedQueryParams } = this.state;
    const { activeCart, isProcessing } = this.props;

    if (
      !productsToAdd.length ||
      hasProcesssedQueryParams ||
      !activeCart ||
      isProcessing
    ) {
      return;
    }

    // Empty the productsToAdd array. This ensures that shouldAddProducts
    // selector will not return true again.
    this.setState({
      productsToAdd: [],
    });

    this.props
      .dispatchClearCartAndAddProducts(
        productsToAdd,
        true /* hideWipeoutBanner */,
      )
      .finally(() => {
        this.setState({
          hasProcesssedQueryParams: true,
        });
      });
  }

  isLoading() {
    const { activeCart, cartFetchFailed, isUserDataLoaded } = this.props;
    const { hasProcesssedQueryParams } = this.state;

    // When there's an active cart, we don't consider the cart fetch completed
    // until the query params have been successfully processed.
    const hasFetchedCart =
      (activeCart && hasProcesssedQueryParams) || cartFetchFailed;

    return !hasFetchedCart || !isUserDataLoaded;
  }

  render() {
    const contentfulProducts = this.props.data.allContentfulProduct.nodes;
    const { updatePageData, cartFetchFailed, isLoggedIn } = this.props;

    const shouldRedirectOnLoad = cartFetchFailed && !isLoggedIn;

    return (
      <>
        <PageSEO {...this.state.seo} />
        <LoadingWrapper
          isLoading={this.isLoading()}
          shouldRedirectOnLoad={shouldRedirectOnLoad}
          redirectPath="/login"
          redirectState={{ redirect: "/cart" }}
        >
          <Cart
            updatePageData={updatePageData}
            contentfulProducts={contentfulProducts}
          />
        </LoadingWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: userSelectors.isLoggedIn(state),
    activeCart: cartSelectors.activeCart(state),
    isProcessing: cartSelectors.isProcessing(state),
    cartFetchFailed: cartSelectors.fetchFailed(state),
    isUserDataLoaded: appSelectors.isUserDataLoaded(state),
    validPlanIds: state.plans.allIds,
  };
};

export default connect(mapStateToProps, {
  dispatchClearCartAndAddProducts: clearCartAndAddProducts,
})(CartPage);

export const query = graphql`
  query CartQuery($locale: String!) {
    allContentfulProduct(filter: { node_locale: { eq: $locale } }) {
      nodes {
        name {
          name
        }
        sku
        contentfulId: contentful_id
        summary
        stockStatus
        productSubhead
        cartImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 200
            quality: 100
          )
          description
        }
      }
    }
  }
`;
